import React from "react";
import * as styles from "./aboutUsDetails.module.scss";
import {Col, Row} from "react-bootstrap";
import {GatsbyImage} from "gatsby-plugin-image";
import JSONData from "../../../content/aboutUsDetailsData.json"
import ListBulletBigSVG from "../../svg/listBulletBig.svg";
import {graphql, useStaticQuery} from "gatsby";


export default function AboutUsDetails() {

    const data = useStaticQuery(graphql`{
    image1: file(relativePath: {eq: "about-us/details/image-1.png"}) {
      childImageSharp {
        gatsbyImageData(quality: 70, layout: CONSTRAINED)
      }
    }}`);

    return <div className={styles.cardWrapper}>

        <Row>
            <Col className={styles.imageWrapper} xl={{offset: 1, span: 4}}>
                <GatsbyImage className={styles.image} image={data.image1.childImageSharp.gatsbyImageData}
                             alt={"person"}/>
            </Col>
            <Col className={styles.pointerListColWrapper} md={{offset: 1, span: 10}} xl={{offset: 1, span: 5}}>
                <div className={styles.text}>
                    {JSONData.textFirst}
                </div>
                <div>
                    {JSONData.pointerList.map((data: any, idx: number) => {
                        return <ListPoint key={idx} header={data.header} text={data.text}/>
                    })}
                </div>
            </Col>
        </Row>

        <Row className={styles.secondRowWrapper}>
            <Col className={styles.secondTextWrapper} xs={{offset: 0, span: 12}} md={{offset: 1, span: 4}}>
                <div className={styles.text}>
                    {JSONData.textSecond}
                </div>
            </Col>
            <Col xs={{span: 12}} md={{span: 7}}>
                <div className={styles.statsWrapper}>
                    {JSONData.statistics.map((data: any, idx: number) => <Statistic key={idx} text={data.text}
                                                                                    stat={data.stat}/>)}
                </div>
            </Col>
        </Row>

    </div>
}

function ListPoint(props: StatisticProps) {
    return <div className={styles.listWrapper}>
        <div className={styles.listHeader}>
            <ListBulletBigSVG/>
            {props.header}
        </div>
        <div className={styles.listText}>
            {props.text}
        </div>
    </div>
}

interface StatisticProps {
    header: string,
    text: string
}

function Statistic(props: { stat: string, text: string }) {
    return <div className={styles.statWrapper}>
        <div className={styles.stat}>{props.stat}</div>
        <div className={styles.statText}>{props.text}</div>
    </div>
}
