import React from "react";
import * as styles from "./aboutUsExplanation.module.scss";
import {GatsbyImage} from "gatsby-plugin-image";
import {graphql, useStaticQuery} from "gatsby";
import JSONData from "../../../content/aboutUsExplanationData.json"

export default function AboutUsExplanation() {

    const data = useStaticQuery(graphql`{
    image1: file(relativePath: {eq: "about-us/explanation/image-1.png"}) {
            childImageSharp {
                gatsbyImageData(quality: 70, layout: CONSTRAINED)
            }
        },
        image2: file(relativePath: {eq: "about-us/explanation/image-2.png"}) {
            childImageSharp {
                gatsbyImageData(quality: 70, layout: CONSTRAINED)
            }
        },
        image3: file(relativePath: {eq: "about-us/explanation/image-3.png"}) {
            childImageSharp {
                gatsbyImageData(quality: 70, layout: CONSTRAINED)
            }
        }
    }`);

    return <div>
        <div className={styles.textWrapper}>
            <div className={styles.text}>
                {JSONData.text}
            </div>
        </div>
        <div className={styles.images}>
            <div className={styles.imageWrapper}>
                <GatsbyImage className={styles.image} alt={"person"}
                             image={data.image1.childImageSharp.gatsbyImageData}/>
            </div>
            <div className={styles.imageWrapper}>
                <GatsbyImage className={`${styles.image} ${styles.imageLower}`} alt={"person"}
                             image={data.image2.childImageSharp.gatsbyImageData}/>
            </div>
            <div className={`${styles.imageWrapper}  ${styles.imageHideMobile}`}>
                <GatsbyImage className={styles.image} alt={"person"}
                             image={data.image3.childImageSharp.gatsbyImageData}/>
            </div>
        </div>
    </div>
}
