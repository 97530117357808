// extracted by mini-css-extract-plugin
export var cardWrapper = "aboutUsDetails-module--cardWrapper--c6edf";
export var imageWrapper = "aboutUsDetails-module--imageWrapper--6b9ea";
export var listHeader = "aboutUsDetails-module--listHeader--3c2eb";
export var listText = "aboutUsDetails-module--listText--f2d96";
export var listWrapper = "aboutUsDetails-module--listWrapper--c1d82";
export var pointerListColWrapper = "aboutUsDetails-module--pointerListColWrapper--7a9b4";
export var secondRowWrapper = "aboutUsDetails-module--secondRowWrapper--a2be4";
export var secondTextWrapper = "aboutUsDetails-module--secondTextWrapper--d82d5";
export var stat = "aboutUsDetails-module--stat--9323c";
export var statText = "aboutUsDetails-module--statText--75ca4";
export var statWrapper = "aboutUsDetails-module--statWrapper--8d126";
export var statsWrapper = "aboutUsDetails-module--statsWrapper--cb435";
export var text = "aboutUsDetails-module--text--38b78";