import Layout from "../components/layout/layout";
import Header from "../components/header/header";
import Footer from "../components/footer/footer";
import PageHero from "../components/pageHero/pageHero";
import JSONData from "../../content/aboutUsPageHeroData.json";
import React from "react";
import AboutUsExplanation from "../components/aboutUsExplanation/aboutUsExplanation";
import AboutUsDetails from "../components/aboutUsDetails/aboutUsDetails";
import Cta from "../components/cta/cta";
import AboutUsServices from "../components/extendedServices/aboutUsServices";
import DiversitySection from "../components/ourServices/diversitySection/diversitySection";
import SocialActions from "../components/socialActions/socialActions";
import * as styles from "../styles/aboutUs.module.scss"
import OurTeam from "../components/team/ourTeam";
import {Helmet} from "react-helmet";


export default function AboutUsPage() {
    return <>
        <Helmet>
            <meta charSet="utf-8" />
            <html lang="en" />
            <title>About us - Recoded Recruitment</title>
        </Helmet>
        <Layout
            header={<Header transparent={true}/>}
            footer={<Footer/>}
        >
            <PageHero textMaxWidth={"810px"} text={JSONData.text} sectionTitle={JSONData.sectionTitle}
                      header={JSONData.header}/>
            <AboutUsExplanation/>
            <AboutUsDetails/>
            <OurTeam/>
            <div className={styles.tileWrapper}>
                <DiversitySection/>
            </div>
            <SocialActions/>
            <AboutUsServices/>
            {/* <Cta/> */}
            <div style={{ height: '140px' }}></div>
        </Layout>
    </>
}
